<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Lewati"
      back-button-text="Sebelumnya"
      next-button-text="Selanjutnya"
      class="mb-3"
      @on-complete="formSubmitted"
    >

      <!-- accoint details tab -->
      <tab-content
        title="Informasi Pengguna"
        :before-change="validationForm"
      >
        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Informasi Pengguna
              </h5>
              <small class="text-muted">
                Lengkapi Informasi Anda
              </small>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nama Depan"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="firstName"
                  rules="required"
                >
                  <b-form-input
                    id="firstName"
                    v-model="firstName"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Masukan Nama Depan"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nama Belakang"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="lastName"
                  rules="required"
                >
                  <b-form-input
                    id="lastName"
                    v-model="lastName"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Masukan Nama Belakang"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Jenis Kelamin"
                label-for="Gender"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Gender"
                  vid="Gender"
                  rules="required"
                >
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="gender"
                      name="gender"
                      value="Laki-Laki"
                    >
                      Laki-Laki
                    </b-form-radio>
                    <b-form-radio
                      v-model="gender"
                      name="gender"
                      value="Perempuan"
                    >
                      Perempuan
                    </b-form-radio>
                  </div>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Tanggal Lahir"
                label-for="dateOfBirth"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tanggal Lahir"
                  rules="required"
                >
                  <b-form-datepicker
                    id="example-datepicker"
                    v-model="dateOfBirth"
                    class="mb-1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- profil bisnis tab -->
      <tab-content
        title="Profil Bisnis"
        :before-change="validationFormInfo"
      >
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Profil Bisnis
              </h5>
              <small class="text-muted">Lengkapi Profil Bisnis Anda</small>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nama Bisnis"
                label-for="bisnis-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nama Bisnis"
                  rules="required"
                >
                  <b-form-input
                    id="bisnis-name"
                    v-model="companyName"
                    placeholder="Masukan Nama Bisnis"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Website Bisnis"
                label-for="website-business-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Website Bisnis"
                  rules=""
                >
                  <b-form-input
                    id="website-business-name"
                    v-model="companySite"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Masukan Website Bisnis"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Tipe"
                rules="required"
              >
                <b-form-group
                  label="Tipe"
                  label-for="tipe"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    id="tipe"
                    v-model="ownershipTypeID"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="countryName"
                    :selectable="option => ! option.value.includes('select_value')"
                    placeholder="Pilih Tipe Kepemilikan Usaha"
                    label="text"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="NPWP Perusahaan"
                rules=""
              >
                <b-form-group
                  label="NPWP Perusahaan"
                  label-for="npwp"
                  :state="errors.length > 0 ? false:null"
                >
                  <b-form-input
                    id="npwp"
                    v-model="npwp"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Masukan NPWP Perusahaan"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Provinsi"
                rules="required"
              >
                <b-form-group
                  label="Provinsi"
                  label-for="provinsi"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    id="provinsi"
                    v-model="province"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="countryName"
                    :selectable="option => ! option.value.includes('select_value')"
                    placeholder="Pilih Provinsi"
                    label="text"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Kabupaten"
                rules="required"
              >
                <b-form-group
                  label="Kabupaten"
                  label-for="kabupaten"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    id="kabupaten"
                    v-model="province"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="countryName"
                    :selectable="option => ! option.value.includes('select_value')"
                    placeholder="Pilih Kabupaten"
                    label="text"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="12">
              <validation-provider
                #default="{ errors }"
                name="Alamat"
                rules="required"
              >
                <b-form-group
                  label="Alamat"
                  label-for="alamat"
                  :state="errors.length > 0 ? false:null"
                >
                  <b-form-textarea
                    id="alamat"
                    placeholder="Pilih Alamat"
                    rows="8"
                    v-model="address"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Jenis Usaha"
                rules="required"
              >
                <b-form-group
                  label="Jenis Usaha"
                  label-for="jenis-usaha"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    id="jenis-usaha"
                    v-model="companyTypeID"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="countryName"
                    :selectable="option => ! option.value.includes('select_value')"
                    placeholder="Pilih Jenis Usaha"
                    label="text"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Nama Jenis Usaha"
                rules="required"
              >
                <b-form-group
                  label="Nama Jenis Usaha"
                  label-for="nama-jenis-usaha"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    id="nama-jenis-usaha"
                    v-model="companyTypeNameID"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="countryName"
                    :selectable="option => ! option.value.includes('select_value')"
                    placeholder="Pilih Nama Jenis Usaha"
                    label="text"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- metode beriklan tab  -->
      <tab-content
        title="Metode Beriklan"
        :before-change="validationFormAddress"
      >
        <validation-observer
          ref="addressRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Pilih Metode Beriklanmu!
              </h5>
            </b-col>

            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Beli Kredit
              </h5>
            </b-col>
            <b-col cols="12" class="text-justify">
              Kredit iklan akan digunakan untuk pembayaran bagi influencers yang mengikuti campaign kamu. Kamu dapat memilih untuk melewati langkah ini sekarang, namun campaign baru kamu akan aktif setelah kamu melakukan pembelian kredit iklan.
              <b-row>
                <b-col cols="12" class="text-right">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                  >
                    Beli Kredit Iklan
                  </b-button>
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Ajukan Verified
              </h5>
            </b-col>
            <b-col cols="12" class="text-justify">Dengan mengajukan akun sebagai verified, kamu tidak perlu membeli kredit iklan karena pembayaran akan dilakukan dengan sistem termin
              <b-row>
                <b-col cols="12" class="text-right">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                  >
                    Ajukan Verified
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- social link -->
      <!-- <tab-content
        title="Social Links"
        :before-change="validationFormSocial"
      >
        <validation-observer
          ref="socialRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Social Links
              </h5>
              <small class="text-muted">Enter Your Social Links</small>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Twitter"
                label-for="twitter"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Twitter"
                  rules="required|url"
                >
                  <b-form-input
                    id="twitter"
                    v-model="twitterUrl"
                    :state="errors.length > 0 ? false:null"
                    placeholder="https://twitter.com/abc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Facebook"
                label-for="facebook"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Facebook"
                  rules="required|url"
                >
                  <b-form-input
                    id="facebook"
                    v-model="facebookUrl"
                    :state="errors.length > 0 ? false:null"
                    placeholder="https://facebook.com/abc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Google+"
                label-for="google-plus"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Google+"
                  rules="required|url"
                >
                  <b-form-input
                    id="google-plus"
                    v-model="googleUrl"
                    :state="errors.length > 0 ? false:null"
                    placeholder="https://plus.google.com/abc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="LinkedIn"
                label-for="linked-in"
              >
                <validation-provider
                  #default="{ errors }"
                  name="LinkedIn"
                  rules="required|url"
                >
                  <b-form-input
                    id="linked-in"
                    v-model="linkedinUrl"
                    :state="errors.length > 0 ? false:null"
                    placeholder="https://linkedin.com/abc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content> -->
    </form-wizard>

  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadio,
  BFormDatepicker,
  BFormTextarea,
  BButton
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { codeIcon } from './code'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormRadio,
    BFormDatepicker,
    BFormTextarea,
    BButton
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      firstName: 'prima',
      lastName: 'mr',
      dateOfBirth: '2020-01-01',
      gender: 'Laki-Laki',

      companyName: 'Digi Corp',
      companySite: 'http://digirans.com',
      ownershipTypeID: '1',
      npwp: '123451234512345',
      province: 'Jawa Barat',
      city: 'Bandung',
      address: 'Jl. A.H Nasution No.1',
      companyTypeID: '1',
      companyTypeNameID: '1',

      selectedContry: '',
      selectedLanguage: '',
      name: '',
      emailValue: '',
      PasswordValue: '',
      passwordCon: '',
      first_name: '',
      last_name: '',
      landMark: '',
      pincode: '',
      twitterUrl: '',
      facebookUrl: '',
      googleUrl: '',
      linkedinUrl: '',
      city: '',
      required,
      email,
      codeIcon,
      countryName: [
        { value: 'select_value', text: 'Select Value' },
        { value: 'Russia', text: 'Russia' },
        { value: 'Canada', text: 'Canada' },
        { value: 'China', text: 'China' },
        { value: 'United States', text: 'United States' },
        { value: 'Brazil', text: 'Brazil' },
        { value: 'Australia', text: 'Australia' },
        { value: 'India', text: 'India' },
      ],
      languageName: [
        { value: 'nothing_selected', text: 'Nothing Selected' },
        { value: 'English', text: 'English' },
        { value: 'Chinese', text: 'Mandarin Chinese' },
        { value: 'Hindi', text: 'Hindi' },
        { value: 'Spanish', text: 'Spanish' },
        { value: 'Arabic', text: 'Arabic' },
        { value: 'Malay', text: 'Malay' },
        { value: 'Russian', text: 'Russian' },
      ],
    }
  },
  methods: {
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
